import { render, staticRenderFns } from "./EventTitle.vue?vue&type=template&id=0d4c32ba&scoped=true"
import script from "./EventTitle.vue?vue&type=script&lang=js"
export * from "./EventTitle.vue?vue&type=script&lang=js"
import style0 from "./EventTitle.vue?vue&type=style&index=0&id=0d4c32ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d4c32ba",
  null
  
)

export default component.exports