<template>
  <div id="order" v-if="order.cause">
    <header>
      <order-cause :cause="order.cause" @save="updateOrder" />
      <div class="style">
        <div class="left">
          <order-plaintiff :plaintiff="order.plaintiff" @save="updateOrder" />
          <div class="vs">vs.</div>
          <order-defendant :defendant="order.defendant" @save="updateOrder" />
        </div>
        <div class="middle">
          <span v-for="i in order.hurricanes">
            &sect;
            <br />
          </span>
        </div>
        <div class="right">
          <order-venue :venue="order.venue" @save="updateOrder" />
        </div>
      </div>
    </header>
    <order-title :title="order.title" @save="updateOrder" />
    <order-introduction :body="order.introduction" @save="updateOrder" />
    <order-event
      v-for="event in order.events"
      v-on:reload="loadOrder"
      :date="event.date"
      :description="event.description"
      :eventId="event.id"
      :key="event.id"
      :law="event.law"
      :title="event.title"
    >
    </order-event>
    <order-closing :body="order.closing" @save="updateOrder" />
  </div>
</template>

<script>
  import OrderCause from './OrderCause.vue';
  import OrderClosing from './OrderClosing.vue';
  import OrderDefendant from './OrderDefendant.vue';
  import OrderEvent from './OrderEvent.vue';
  import OrderIntroduction from './OrderIntroduction.vue';
  import OrderPlaintiff from './OrderPlaintiff.vue';
  import OrderTitle from './OrderTitle.vue';
  import OrderVenue from './OrderVenue.vue';

  export default {
    components: {
      OrderCause,
      OrderClosing,
      OrderDefendant,
      OrderEvent,
      OrderIntroduction,
      OrderPlaintiff,
      OrderTitle,
      OrderVenue,
    },
    props: {
      edit: String,
      path: String,
    },
    data() {
      return {
        order: {}
      };
    },
    computed: {
      editable() {
        return $('#deadlines').data('edit');
      },
    },
    methods: {
      loadOrder() {
        const vm = this
        $.get($('#deadlines').data('path'), function(data) {
          vm.order = data;
        });
      },
      updateOrder: function(field, value) {
        const changes = { order: { [`${field}`] : value } };
        this.$http.put(this.path, changes);
      },
    },
    mounted: function () {
      this.loadOrder();
    },
  }
</script>
