<template>
  <component
    :is="type"
    @blur="save"
    @click="edit"
    :class="[{editable: this.$parent.$parent.editable}, 'event-date']"
    >
    {{ value }}
  </component>
</template>

<script>
  import 'bootstrap/js/dist/modal'
  import 'bootstrap-datepicker'

  export default {
    data() {
      return {
        editing: false,
        value: this.date,
      };
    },
    props: {
      date: String,
      id: Number,
    },
    computed: {
      type() {
        return this.editing ? 'input' : 'div';
      },
    },
    methods: {
      edit: function() {
        if ( ! this.editing && this.$parent.$parent.editable ) {
          this.editing = true;
          setTimeout(function(){
            this.$el.value = this.value;
            $(this.$el).datepicker({
              autoclose: true,
              daysOfWeekDisabled: [0,6],
            }).on('changeDate', function(){
              setTimeout(function(){
                this.save();
              }.bind(this), 200);
            }.bind(this));
            this.$el.focus();
          }.bind(this));
        }
      },
      save: function() {
        if ( this.editing ) {
          // They probably just changed the month.
          if ( $('.datepicker-dropdown').length > 0 ) {
            return false;
          }

          // They clicked away without selecting a new date.
          if ( this.$el.value == this.value ) {
            this.editing = false;
            return false;
          }

          // They actually selected a new date.
          setTimeout(function(){
            const changes = { event: { date: this.$el.value } };
            this.$http.put('/events/' + this.id + '/try', changes).then(response => {
              this.value = this.$el.value;
              this.editing = false;
            }, response => {
              if ( response.statusText == 'Conflict' ) {
                $('#modal').empty();
                $('#modal').html(response.body.modal);
                $('#modal').modal('show');
              } else {
                alert('We are sorry, we were unable to change this date.');
                this.$el.focus();
              }
            });

          }.bind(this), 500);
        }
      },
    }
  }
</script>

<style scoped>
  .event-date {
    float: left;
    width: 120px;
  }
  div.event-date {
    border: 1px solid #FFFFFF;
    font-weight: bold;
  }
  div.event-date.editable {
    cursor: pointer;
  }
  div.event-date.editable:hover {
    border: 1px solid #999999;
  }
  input.event-date {
    background: #EEEEEE;
    border: 1px dashed #999999;
  }
</style>
