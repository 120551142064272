var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { class: [{ ineditable: !this.$parent.editable }, "event"] },
    [
      this.$parent.editable
        ? _c(
            "button",
            {
              staticClass: "add-button",
              attrs: { title: "Add Event" },
              on: { click: _vm.addEvent },
            },
            [_c("i", { staticClass: "fa fa-plus-circle" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("event-date", { attrs: { date: _vm.date, id: _vm.eventId } }),
      _vm._v(" "),
      _c("event-title", {
        attrs: { title: _vm.title },
        on: { save: _vm.save },
      }),
      _vm._v(" "),
      _c("event-description", {
        attrs: { description: _vm.description },
        on: { save: _vm.save },
      }),
      _vm._v(" "),
      this.$parent.editable
        ? _c(
            "a",
            {
              staticClass: "delete-event",
              attrs: {
                "data-event-type": _vm.title,
                href: _vm.eventPath,
                title: "Delete " + _vm.title,
              },
            },
            [_c("i", { staticClass: "fa fa-trash" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showLaw
        ? _c(
            "a",
            {
              staticClass: "view-law as-modal",
              attrs: { href: _vm.lawPath, title: "View Law" },
            },
            [_c("i", { staticClass: "fa fa-info-circle" })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }