import { render, staticRenderFns } from "./OrderTitle.vue?vue&type=template&id=feb91120&scoped=true"
import script from "./OrderTitle.vue?vue&type=script&lang=js"
export * from "./OrderTitle.vue?vue&type=script&lang=js"
import style0 from "./OrderTitle.vue?vue&type=style&index=0&id=feb91120&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "feb91120",
  null
  
)

export default component.exports