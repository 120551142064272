<template>
  <component
    :is="type"
    @blur="save"
    @click="edit"
    :class="[{editable: this.$parent.$parent.editable}, 'description']"
    v-html="value"
    ></component>
</template>

<script>
  export default {
    data() {
      return {
        editing: false,
        value: this.description,
      };
    },
    props: {
      description: String,
    },
    computed: {
      type() {
        return this.editing ? 'textarea' : 'div';
      },
    },
    methods: {
      edit: function() {
        if ( ! this.editing && this.$parent.$parent.editable ) {
          this.editing = true;
          setTimeout(function(){
            if ( this.value == 'Enter description here.' ) {
              this.$el.textContent = this.value.trim();
              this.$el.select();
            } else {
              this.$el.focus();
              this.$el.textContent = this.value.trim();
            }
          }.bind(this));
        }
      },
      save: function() {
        if ( this.editing ) {
          this.value = this.$el.value;
          this.$emit('save', 'description', this.value);
          this.editing = false;
        }
      },
    }
  }
</script>

<style scoped>
  .description {
    margin-left: 120px;
  }
  div.description.editable {
    border: 1px solid #FFFFFF;
    cursor: pointer;
  }
  div.description.editable:hover {
    border: 1px solid #999999;
  }
  textarea.description {
    background: #EEEEEE;
    border: 1px dashed #999999;
    height: 7em;
    width: calc(100% - 120px);
  }
</style>
