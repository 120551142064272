<template>
  <component
    :is="type"
    @blur="save"
    @click="edit"
    class=""
    :class="[{editable: this.$parent.$parent.editable}, 'event-title']"
    >
    {{ value }}
  </component>
</template>

<script>
  export default {
    data() {
      return {
        editing: false,
        value: this.title,
      };
    },
    props: {
      title: String,
    },
    computed: {
      type() {
        return this.editing ? 'input' : 'div';
      },
    },
    methods: {
      edit: function() {
        if ( ! this.editing && this.$parent.$parent.editable ) {
          this.editing = true;
          setTimeout(function(){
            this.$el.value = this.value;
            if ( this.value == 'Custom Deadline' ) {
              this.$el.select();
            } else {
              this.$el.focus();
            }
          }.bind(this));
        }
      },
      save: function() {
        if ( this.editing ) {
          this.value = this.$el.value;
          this.$emit('save', 'title', this.value);
          this.editing = false;
        }
      },
    }
  }
</script>

<style scoped>
  div.event-title {
    border: 1px solid #FFFFFF;
    font-weight: bold;
    margin-left: 120px;
  }
  div.event-title.editable {
    cursor: pointer;
  }
  div.event-title.editable:hover {
    border: 1px solid #999999;
  }
  input.event-title {
    background: #EEEEEE;
    border: 1px dashed #999999;
    width: calc(100% - 120px);
  }
</style>
