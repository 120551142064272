import $ from 'jquery'
import 'bootstrap-datepicker';
import 'select2';
import 'select2/dist/css/select2.css';
import 'shepherd.js/dist/css/shepherd.css';
import './vendor/john_hancock';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Shepherd from 'shepherd.js';
import Tablesort from 'tablesort';

let dp = {
  calendar : {
    destroyPopovers: function() {
      $('.popover').remove();
    },
  },
  helpers: {
    copyMe: function(event) {
      const text = $(event.target);
      text.select();
      document.execCommand("copy");
    },
  },
  ui: {
    calendar: {},
    initialize: {
      calendar: function () {
        var calendarEl = document.getElementById('calendar');
        if ( ! calendarEl ) { return; }

        dp.ui.calendar = new Calendar(calendarEl, {
          plugins: [
            dayGridPlugin,
            interactionPlugin,
          ],
          height: 'parent',
          headerToolbar: {
            start: 'prev,next title',
            center: '',
            end: '',
          },
          eventSources: [
            '/events/deadlines',
            '/events/holidays',
          ],
          eventDidMount: function (entry) {
            $(entry.el).popover({
              container: 'body',
              html: true,
              title: entry.event.title,
              content: entry.event.extendedProps.description,
              placement: 'top',
            });
          },
          dateClick: dp.calendar.destroyPopovers,
        });

        dp.ui.calendar.render();
      },
      datepickers: function() {
        $('.datepicker').datepicker('remove');
        $('.datepicker').datepicker({
          autoclose: true,
          daysOfWeekDisabled: [0,6],
        });
      },
      tablesort: function() {
        const table = $('.orders-index .orders table');
        if ( table.length > 0 ) {
          new Tablesort( table[0] );
        }
      },
      tagging: function() {
        $('.select2').select2();
      },
      tour: function() {
        const tour = new Shepherd.Tour({
          useModalOverlay: true,
          defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            cancelIcon: { enabled: true },
          },
        });
        return tour;
      },
    },
    modal: {
      open: function(event) {
        event.preventDefault();
        const button = $(event.target).closest('.as-modal');
        const url = button.prop('href');
        $('#modal').empty();
        $('#modal').load(url);
        $('#modal').modal('show');
        setTimeout(function(){
          dp.ui.initialize.datepickers();
        }, 500);
      },
    },
  },
  orders: {
    delete: function(event) {
      event.preventDefault();
      const button = $(event.target).closest('.delete-event');
      const deadline = button.closest('.event');
      const event_type = button.data('event-type');
      const url = button.attr('href');

      if ( confirm('Are you sure that you want to remove "' + event_type + '"?') ) {
        $.ajax({
          method: 'DELETE',
          url: url,
          success: function(data, textStatus, jqXHR){
            deadline.slideUp();
          },
          error: function(data, textStatus, jqXHR) {
            alert(data.responseJSON.join(''));
          },
        });
      }
    },
    level: function(event) {
      const input = $(event.target);
      const value = input.val();
      if ( value == '1' ) {
        $('.l1-fields').slideDown();
        $('.l2-fields').slideUp();
        $('#order_petition_dt').val('');
        $('#order_response_dt').val('');
      } else {
        $('.l1-fields').slideUp();
        $('.l2-fields').slideDown({
          start: function () {
            $(this).css({
              display: "flex"
            })
          }
        });
        $('#order_trial_dt').val('');
      }
    },
    openOrder: function(event) {
      const row = $(event.target).closest('tr');
      const path = row.data('path');
      location.href = path;
    },
    validate: function(event) {
      event.preventDefault();
      var valid = false;
      $('.require-one:visible').each(function() {
        if ( $(this).val() != '' ) {
          valid = true;
        }
      });
      if ( valid ) {
        $('.order-form').submit();
      } else {
        alert('Please enter at least one date.');
      }
    },
  },
  signature: {
    clear: function(event) {
      event.preventDefault();
      var canvas = document.getElementById("JohnHancock-canvas");
      var context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    },
  },
  signup: {
    toggleButton: function(event) {
      let agreed = $('#terms').is(':checked');
      $('#signup').prop('disabled', !agreed);
    },
  },
};

$(document).on('turbolinks:before-cached', function() {
  dp.ui.calendar.destroy();
});

$(document).on('turbolinks:load', function() {
  dp.ui.initialize.calendar();
  dp.ui.initialize.datepickers();
  dp.ui.initialize.tablesort();
  dp.ui.initialize.tagging();
  window.tour = dp.ui.initialize.tour();
});

$(document).ready(function() {
  $(document).on('change', '.order-level', dp.orders.level);
  $(document).on('click', '.as-modal', dp.ui.modal.open);
  $(document).on('click', '.copy-me', dp.helpers.copyMe);
  $(document).on('click', '.fc-button', dp.calendar.destroyPopovers);
  $(document).on('click', '#clear-signature', dp.signature.clear);
  $(document).on('click', '#terms', dp.signup.toggleButton);
  $(document).on('click', '.order-form input[type="submit"]', dp.orders.validate);
  $(document).on('click', '.orders-show .delete-event', dp.orders.delete);
  $(document).on('click', '.orders-index .open', dp.orders.openOrder);
});
