var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.type,
    {
      tag: "component",
      class: [{ editable: this.$parent.$parent.editable }, "event-date"],
      on: { blur: _vm.save, click: _vm.edit },
    },
    [_vm._v("\n  " + _vm._s(_vm.value) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }