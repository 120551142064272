// Entry point for the build script in your package.json
import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

import './backyard.js'
import './deadlinepro.js'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require('bootstrap/js/dist/alert');
require('bootstrap/js/dist/button');
require('bootstrap/js/dist/carousel');
require('bootstrap/js/dist/collapse');
require('bootstrap/js/dist/dropdown');
require('bootstrap/js/dist/index');
require('bootstrap/js/dist/modal');
require('bootstrap/js/dist/popover');
require('bootstrap/js/dist/scrollspy');
require('bootstrap/js/dist/tab');
require('bootstrap/js/dist/toast');
require('bootstrap/js/dist/tooltip');
require('bootstrap/js/dist/util');

require('tablesort');

// import './order.js'
import SchedulingOrder from './components/SchedulingOrder.vue';
import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';

Vue.use(TurbolinksAdapter);
Vue.use(VueResource);
Vue.http.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

window.App = new Vue({
  render: h => h(SchedulingOrder),
});
