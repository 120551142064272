<template>
  <component
    :is="type"
    @blur="save"
    @click="edit"
    :class="[{editable: this.$parent.editable}, 'plaintiff']"
    >{{ value }}</component>
</template>

<script>
  export default {
    data() {
      return {
        editing: false,
        value: this.plaintiff,
      };
    },
    props: {
      plaintiff: String,
    },
    computed: {
      type() {
        return this.editing ? 'textarea' : 'div';
      },
    },
    methods: {
      edit: function() {
        if ( ! this.editing && this.$parent.editable ) {
          this.editing = true;
          setTimeout(function(){
            this.$el.focus();
            this.$el.textContent = this.value.trim();
          }.bind(this));
        }
      },
      save: function() {
        if ( this.editing ) {
          this.value = this.$el.value;
          this.$emit('save', 'plaintiff', this.value);
          this.editing = false;
        }
      },
    }
  }
</script>

<style scoped>
  .plaintiff {
  }
  div.plaintiff.editable {
    border: 1px solid #FFFFFF;
    cursor: pointer;
  }
  div.plaintiff.editable:hover {
    border: 1px solid #999999;
  }
  textarea.plaintiff {
    background: #EEEEEE;
    border: 1px dashed #999999;
    height: 7em;
    width: 100%;
  }
</style>
