<template>
  <article :class="[{ineditable: !this.$parent.editable}, 'event']">
    <button
      @click="addEvent"
      class="add-button"
      title="Add Event"
      v-if="this.$parent.editable"
    >
      <i class="fa fa-plus-circle"></i>
    </button>
    <event-date :date="date" :id="eventId"></event-date>
    <event-title :title="title" v-on:save='save'></event-title>
    <event-description :description="description" v-on:save='save'></event-description>
    <a
      :data-event-type="title"
      :href="eventPath"
      :title="'Delete ' + title"
      v-if="this.$parent.editable"
      class="delete-event"
    >
      <i class="fa fa-trash"></i>
    </a>
    <a
      :href="lawPath"
      v-if="showLaw"
      class="view-law as-modal"
      title="View Law"
    >
      <i class="fa fa-info-circle"></i>
    </a>
  </article>
</template>

<script>
  import EventDate from './EventDate.vue';
  import EventDescription from './EventDescription.vue';
  import EventTitle from './EventTitle.vue';

  export default {
    components: {
      EventDate,
      EventDescription,
      EventTitle,
    },
    props: {
      date: String,
      description: String,
      eventId: Number,
      law: String,
      title: String,
    },
    computed: {
      eventPath() {
        return '/events/' + this.eventId;
      },
      lawPath() {
        return '/events/' + this.eventId + '/law';
      },
      showLaw() {
        return this.law && this.$parent.editable;
      },
      type() {
        return true;
      },
    },
    methods: {
      addEvent: function() {
        this.$http.post('/api/v1/events/', { before: this.eventId })
        .then(
          function() {
            this.$emit('reload');
          },
          function() {
            // TODO: Add error handling
          }
        );
      },
      save: function(field, value) {
        const changes = { event: { [`${field}`] : value } };
        this.$http.put('/api/v1/events/' + this.eventId, changes);
      },
    }
  }
</script>

<style scoped>
  .add-button {
    background: initial;
    border: 1px solid transparent;
    color: transparent;
    cursor: pointer;
    display: block;
    text-align: center;
    width: 100%;
  }
  .add-button:hover,
  .add-button.highlighted-in-tour {
    border-color: #999999;
    color: #415C75;
  }
  .event.ineditable {
    margin-bottom: 30px;
  }
</style>
