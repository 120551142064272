<template>
  <component
    :is="type"
    @blur="save"
    @click="edit"
    :class="[{editable: this.$parent.editable}, 'cause']"
    >
    {{ value }}
  </component>
</template>

<script>
  export default {
    data() {
      return {
        editing: false,
        value: this.cause,
      };
    },
    props: {
      cause: String,
    },
    computed: {
      type() {
        return this.editing ? 'input' : 'div';
      },
    },
    methods: {
      edit: function() {
        if ( ! this.editing && this.$parent.editable ) {
          this.editing = true;
          setTimeout(function(){
            this.$el.value = this.value;
            this.$el.focus();
          }.bind(this));
        }
      },
      save: function() {
        if ( this.editing ) {
          this.value = this.$el.value;
          this.$emit('save', 'cause', this.value);
          this.editing = false;
        }
      },
    }
  }
</script>

<style scoped>
  .cause {
    margin-bottom: 20px;
    text-align: center;
  }
  div.cause.editable {
    border: 1px solid #FFFFFF;
    cursor: pointer;
  }
  div.cause.editable:hover {
    border: 1px solid #999999;
  }
  input.cause {
    background: #EEEEEE;
    border: 1px dashed #999999;
    width: 100%;
  }
</style>
