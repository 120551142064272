var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.order.cause
    ? _c(
        "div",
        { attrs: { id: "order" } },
        [
          _c(
            "header",
            [
              _c("order-cause", {
                attrs: { cause: _vm.order.cause },
                on: { save: _vm.updateOrder },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "style" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [
                    _c("order-plaintiff", {
                      attrs: { plaintiff: _vm.order.plaintiff },
                      on: { save: _vm.updateOrder },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "vs" }, [_vm._v("vs.")]),
                    _vm._v(" "),
                    _c("order-defendant", {
                      attrs: { defendant: _vm.order.defendant },
                      on: { save: _vm.updateOrder },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "middle" },
                  _vm._l(_vm.order.hurricanes, function (i) {
                    return _c("span", [
                      _vm._v("\n          §\n          "),
                      _c("br"),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("order-venue", {
                      attrs: { venue: _vm.order.venue },
                      on: { save: _vm.updateOrder },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("order-title", {
            attrs: { title: _vm.order.title },
            on: { save: _vm.updateOrder },
          }),
          _vm._v(" "),
          _c("order-introduction", {
            attrs: { body: _vm.order.introduction },
            on: { save: _vm.updateOrder },
          }),
          _vm._v(" "),
          _vm._l(_vm.order.events, function (event) {
            return _c("order-event", {
              key: event.id,
              attrs: {
                date: event.date,
                description: event.description,
                eventId: event.id,
                law: event.law,
                title: event.title,
              },
              on: { reload: _vm.loadOrder },
            })
          }),
          _vm._v(" "),
          _c("order-closing", {
            attrs: { body: _vm.order.closing },
            on: { save: _vm.updateOrder },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }